#modal-loader {
  padding: 0 !important;
  background: rgba(0, 0, 0, .5) !important;
  overflow: hidden !important;
  position: fixed !important;
  .spinner {
    position: absolute;
    top: calc(45% - 35px);
    left: calc(50% - 35px);
  }
}