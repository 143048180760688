#modal-loader {
  padding: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
  overflow: hidden !important;
  position: fixed !important;
}

#modal-loader .spinner {
  position: absolute;
  top: calc(45% - 35px);
  left: calc(50% - 35px);
}

#main {
  height: 100%;
}

.top-align {
  vertical-align: top;
}

.middle-valign {
  vertical-align: middle;
}

.top-align-imp {
  vertical-align: top !important;
}

.text-small {
  font-size: 12px;
}

.text-strong {
  font-weight: bold;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

form {
  position: relative;
}

.fc_loader {
  z-index: 999999999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.fc_loader .fc_spinner {
  width: 2.28571429rem;
  height: 2.28571429rem;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.9);
  display: block;
  position: absolute;
  top: calc(50% - 1.1rem);
  left: calc(50% - 1.1rem);
  margin: -1.1rem 0 0 0;
  text-align: center;
  z-index: 1000;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 400ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 400ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 400ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 400ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.fc_loader .fc_spinner:after, .fc_loader .fc_spinner:before {
  width: 2.28571429rem;
  height: 2.28571429rem;
  margin: 0 0 0 -1.14285714rem;
}

.fc_loader .fc_spinner:before {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.15);
}

.fc_loader .fc_spinner:after {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  border-radius: 500rem;
  border-style: solid;
  border-width: .2em;
  box-shadow: 0 0 0 1px transparent;
  border-color: #FFFFFF transparent transparent;
}

.fc_loader .fc_spinner_text {
  width: 400px;
  height: 2.28571429rem;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.9);
  display: block;
  position: absolute;
  top: calc(50% + 2em);
  left: calc(50% - 200px);
  margin: -1.1rem 0 0 0;
  text-align: center;
  z-index: 1000;
}

.text-strong {
  font-weight: bold;
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.btn-success,
.btn-warning,
.btn-info,
.btn-danger {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.btn-success {
  border-bottom: 2px solid #258a25;
}

.btn-warning {
  border-bottom: 2px solid #c1842d;
}

.btn-info {
  border-bottom: 2px solid #34869e;
}

.btn-danger {
  border-bottom: 2px solid #9a2a26;
}

.fc-input-sm {
  height: 30px !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
}

.fc-input-sm-value {
  line-height: 30px;
}

.fc-text-danger {
  color: #a94442 !important;
}

.fc-block {
  display: block !important;
  width: 100% !important;
}

.fc-checkbox label {
  margin: 0;
  position: relative;
  border: 1px solid #e1e1e1;
  width: 17px;
  height: 17px;
  outline: none !important;
  cursor: pointer;
}

.fc-checkbox label [type="checkbox"] {
  outline: none !important;
  cursor: pointer;
  opacity: 1;
  -webkit-appearance: none;
  content: ' ';
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid white;
  display: inline-block;
  position: relative;
}

.fc-checkbox label [type="checkbox"]:checked {
  background: #007ea4;
}

.fc-checkbox label [type="checkbox"]:checked.gray {
  background: #333;
}

.fc-checkbox label [type="checkbox"]:checked.orange {
  background: #f7943d;
}

.fc-checkbox label [type="checkbox"]:checked.blue {
  background: #007ba2;
}

.fc-checkbox label [type="checkbox"]:checked.green {
  background: #00a456;
}

.fc-checkbox .fc-label {
  display: inline-block;
  vertical-align: top;
}

.pull-none {
  float: none !important;
}

label {
  cursor: pointer;
}

input[type=checkbox]:not(old) + label,
input[type=radio]:not(old) + label {
  line-height: 1.4em;
}

.table-block {
  width: 100% !important;
}

.dataTables_wrapper .dataTables_length {
  display: inline-block;
}

.dataTables_wrapper .toolbar {
  float: none;
  display: inline-block;
  padding: 0 10px 0 0;
}

.dataTables_wrapper .toolbar .form-control {
  margin-left: 10px;
  margin-right: 10px;
}

.dataTables_wrapper .dataTables_paginate .pagination .paginate_button {
  padding: 0;
  margin: 0 -2px 0;
}

.dataTables_wrapper .dataTable thead th:after {
  right: 20px;
}

.table-unbordered td,
.table-unbordered th {
  border: none !important;
}

.input-group-addon {
  border: 1px solid #e5e5e5;
}

div.dataTables_wrapper div.dataTables_processing {
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  height: auto;
}

.table-vertical-align td, .table-vertical-align th {
  vertical-align: top !important;
}

.modal-header {
  background: #007e99;
}

.modal-header .modal-title {
  color: white;
  font-weight: bold;
}

.nav-tabs > li {
  border-bottom: 1px solid #bed0d5;
}

.nav-tabs > li.active {
  border-bottom: none;
}

.nav-tabs > li.tab-actions {
  border-bottom: none;
  height: 42px;
  float: right;
  padding-left: 3px;
}

.nav-tabs > li.tab-actions .form-control {
  height: 37px;
}

table.dataTable tbody .btn-link,
table.dataTable tbody a {
  cursor: pointer;
}

table.dataTable tbody tr:hover td,
table.dataTable tbody tr:hover th {
  background: #007ba2;
  color: #fff;
}

table.dataTable tbody tr:hover .btn-link,
table.dataTable tbody tr:hover a {
  color: #fff;
  text-decoration: underline;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 8px 10px;
}

.page-title2 {
  margin: 0 0 25px 0;
  line-height: 37px;
}

.input-group .form-control {
  border: 1px solid #e5e5e5;
}

.input-group.input-group-40-60 .form-control:first-child {
  width: 40%;
}

.input-group.input-group-40-60 .form-control:last-child {
  width: 60%;
  margin-left: -1px;
}

.form-group.has-error .form-control {
  border-color: #a94442;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: calc(50% - 10px);
}

.col-actions-100 {
  width: 100px !important;
}

.col-actions-200 {
  width: 200px !important;
}

.dataTables_wrapper .dataTables_length {
  float: none;
}

input[disabled] {
  background: rgba(0, 0, 0, 0.05);
}

.graph-chart-contain {
  width: 98%;
}

.fullscreen, .content-a {
  overflow: auto;
}

.summary-text.not-case h2 {
  text-transform: none;
}

.table > thead > tr > td.ballard,
.table > tbody > tr > td.ballard,
.table > tfoot > tr > td.ballard,
.table > thead > tr > th.ballard,
.table > tbody > tr > th.ballard,
.table > tfoot > tr > th.ballard,
.table > thead > tr.ballard > td,
.table > tbody > tr.ballard > td,
.table > tfoot > tr.ballard > td,
.table > thead > tr.ballard > th,
.table > tbody > tr.ballard > th,
.table > tfoot > tr.ballard > th {
  background-color: #007ba2;
  color: white;
}

.label {
  font-size: 12px;
}

.pdf-page-title {
  background: #007ea4;
  line-height: 40px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.line-height-btn-sm {
  line-height: 30px;
}

.graph-table ul li {
  border-radius: 0 !important;
  width: 19%;
}

.graph-table ul li:first-child {
  height: 78px;
}

.graph-table ul li:last-child {
  border-left: none;
}

.label-blue {
  background: #006ebd;
}

.label-small {
  font-size: 75%;
}

.fc-danger {
  color: red;
}

.fc-success {
  color: #5cb85c;
}

.fc-quicksand {
  font-family: 'Quicksand', sans-serif;
}

.fc-quicksand-bold {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

.fc-panel-danger {
  border-color: #d9534f;
}

.fc-panel-danger > .panel-heading {
  color: white;
  background-color: #d9534f;
  border-color: #d9534f;
}

.fc-panel-warning {
  border-color: #f0ad4e;
}

.fc-panel-warning > .panel-heading {
  color: white;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.fc-panel-primary {
  border-color: #017ea4;
}

.fc-panel-primary > .panel-heading {
  color: white;
  background-color: #017ea4;
  border-color: #017ea4;
}

.square-image {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.square-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.square-image .square-image-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  text-align: center;
}

#page__products_edit .panel-body .file_preview {
  height: 182px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#page__products_edit .panel-body .file_preview img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: 100%;
}

#page__products_edit .panel-body .file_preview .btn {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  text-align: center;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 35px;
  line-height: 166px;
}

#page__products_edit .panel-body .file_preview:hover .btn {
  opacity: 1;
}

.price-list-item-popover.popover {
  max-width: 600px;
  width: 600px;
  padding: 0;
  border: none;
}

.price-list-item-popover.popover > .arrow {
  border-bottom-color: #017ea4;
}

.price-list-item-popover.popover > .arrow:after {
  border-bottom-color: #017ea4;
}

.price-list-item-popover.popover .popover-content {
  padding: 0;
}

.price-list-item-popover.popover .popover-content .panel {
  margin: 0;
  border: none;
}

.price-list-item-popover.popover .popover-content .panel .panel-body .image img {
  max-width: 170px;
}

span[data-toggle="product-hover"] {
  border-bottom: 1px dotted;
  cursor: pointer;
}

[href*="amcharts.com/"] {
  display: none !important;
}

.fc-text-muted {
  color: #ddd;
}

.fc-text-center {
  text-align: center !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #017ea4;
  border: 1px solid #017ea4;
  color: white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: white;
}

.fc-btn-default {
  background-color: #fff;
  border-color: #ccc;
  color: #017ea4;
}
